<template>
  <div>
    <b-modal
      id="modal-stock-keluar"
      size="lg"
      centered
      title="Stock Keluar"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Gudang <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="ms_gudang_id"
            v-model="$v.is_data.ms_gudang_id.$model"
            :options="listGudang"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama"
            track-by="ms_gudang_id"
            placeholder="-- Pilih Gudang --"
            size="sm"
            @select="getBatch, is_data.kode_batch = null, is_data.stock_id = null, is_data.ms_barang_id = null"
            @remove="listBatch = [], listBarang = [], listStock = [], listBatch, is_data.kode_batch = null, is_data.stock_id = null, is_data.ms_barang_id = null"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Barang <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="ms_barang_id"
            v-model="$v.is_data.ms_barang_id.$model"
            :options="listBarang"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama"
            track-by="ms_barang_id"
            placeholder="-- Pilih Barang --"
            size="sm"
            @select="getStock(), getBatch(), is_data.kode_batch = null, is_data.stock_id = null"
            @remove="listStock = [], listBatch = [], is_data.kode_batch = null, is_data.stock_id = null"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Kode Batch <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="kode_batch"
            v-model="$v.is_data.kode_batch.$model"
            :options="listBatch"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="kode_batch"
            track-by="kode_batch"
            placeholder="-- Pilih Batch --"
            size="sm"
            @select="is_data.stock_id = null, getStock()"
            @remove="is_data.stock_id = null, getStock()"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Stock <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="stock_id"
            v-model="$v.is_data.stock_id.$model"
            :options="listStock"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama"
            track-by="stock_id"
            placeholder="-- Pilih Stock --"
            size="sm"
            @select="is_data.kode_batch = {kode_batch: is_data.stock_id.kode_batch}, getStock()"
            @remove="getStock()"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Quantity <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('qty')"
            type="number"
            v-model="$v.is_data.qty.$model"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-stock-keluar')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, between } from "vuelidate/lib/validators";

export default {
  name: "modalInput",
  props:[
    'listJenisAlergi',
    'listKomposisiBarang',

    'listGudang',
  ],
  data() {
    return {
      listBarang: [],
      listStock: [],
      listBatch: [],
      is_data: {
        qty: '',
        kode_batch: '',
        ms_gudang_id: '',
        stock_id: '',
        ms_barang_id: '',
      },
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      kode_batch: { required },
      ms_gudang_id: { required },
      stock_id: { required },
      ms_barang_id: { required },
      qty: { 
        required, 
        between(value){
          return between(0, this.is_data.stock_id ? this.is_data.stock_id.qty_stock : 0)(value)
        }
      },
    },
  },
  mounted() {
    const vm = this
    vm.getList()
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async getList() {
      const vm = this
      vm.busy = true;
      // barang
      let listBarang = await vm.$axios.post("/ms_barang/list");
      // console.log('listBarang', listBarang)
      vm.listBarang = listBarang.data.status == 200 ? listBarang.data.data.map(x => { return {
        ...x,
        nama: x.kode_produk + ' - ' + x.nama_barang
      }}) : []
      vm.busy = false;
    },
    async getBatch() {
      const vm = this
      vm.busy = true;
      // console.log('getBatch',vm.is_data.ms_gudang_id, vm.is_data.ms_barang_id)
      if(vm.is_data.ms_gudang_id && vm.is_data.ms_barang_id){
        // batch
        let listBatch = await vm.$axios.post("/stock/list_batch_by_gudang", {
          ms_gudang_id: vm.is_data.ms_gudang_id.ms_gudang_id, 
          ms_barang_id: vm.is_data.ms_barang_id.ms_barang_id,
        });
        // console.log('listBatch', listBatch)
        vm.listBatch = listBatch.data.status == 200 ? listBatch.data.data.map(x => { return {
          ...x,
        }}) : []
      }else{
        vm.listBatch = []
      }
      vm.busy = false;
    },
    async getStock() {
      const vm = this
      vm.busy = true;
      if(vm.is_data.ms_gudang_id && vm.is_data.ms_barang_id){
        // stock
        let listStock = await vm.$axios.post("/stock/list", {
          ms_gudang_id: vm.is_data.ms_gudang_id.ms_gudang_id, 
          ms_barang_id: vm.is_data.ms_barang_id.ms_barang_id,
          kode_batch: vm.is_data.kode_batch ? vm.is_data.kode_batch.kode_batch : null,
        });
        // console.log('listStock', listStock)
        vm.listStock = listStock.data.status == 200 ? listStock.data.data.map(x => { return {
          ...x,
          nama: x.kode_batch + ' - ' + x.qty_stock
        }}) : []
      }else{
        vm.listStock = []
      }
      vm.busy = false;
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post("/stock_adjustment/register", {
          ...vm.is_data,
          // ms_jenis_alergi_id: vm.is_data.ms_jenis_alergi_id ? vm.is_data.ms_jenis_alergi_id.ms_jenis_alergi_id : null,
          // ms_komposisi_barang_id: vm.is_data.ms_komposisi_barang_id ? vm.is_data.ms_komposisi_barang_id.ms_komposisi_barang_id : null,
          kode_batch: vm.is_data.kode_batch ? vm.is_data.kode_batch.kode_batch : null,
          ms_gudang_id: vm.is_data.ms_gudang_id ? vm.is_data.ms_gudang_id.ms_gudang_id : null,
          stock_id: vm.is_data.stock_id ? vm.is_data.stock_id.stock_id : null,
          ms_barang_id: vm.is_data.ms_barang_id ? vm.is_data.ms_barang_id.ms_barang_id : null,
          qty: vm.is_data.qty - (vm.is_data.qty * 2),
        }).then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN STOCK MASUK",
              showing: true,
            });
            this.$bvModal.hide("modal-stock-keluar");
            this.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: (res.data.message).toUpperCase(),
              showing: true,
            });
          }
        })
        .catch(() => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        qty: '',
        kode_batch: '',
        ms_gudang_id: '',
        stock_id: '',
        ms_barang_id: '',
      };
    },
  },
};
</script>
